import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faClock,
  faEye,
  faBuilding,
  faBars,
  faMapMarkerAlt,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

export default function OffreEmploi(props) {
  const { t, i18n } = useTranslation();
  const [showResults, setShowResults] = React.useState(0);
  const onClick = () => {
    if (showResults == 0) {
      setShowResults(1);
    } else {
      setShowResults(0);
    }
  };

  var detailAvis = {};

  i18n.language == "fr"
    ? (detailAvis = {
        id: props.avis.id,
        libelle: props.avis.libelle,
        ville: props.avis.ville,
      })
    : (detailAvis = {
        id: props.avis.id,
        libelle: props.avis.libelle_ar,
        ville: props.avis.ville_ar,
      });

  return (
    <Row className="wrap" style={{ minHeight: "133px" }}>
      <Col xs="2" lg="2" className="col2">
        <a
          href={`${process.env.PUBLIC_URL}/${i18n.language}/offre-emploi/${props.avis.slug}/${props.avis.id}`}
        >
          <img
            src={"https://rimtic.com/storage/images/" + props.avis.societe.logo}
            style={{ width: "100%", height: "100%" }}
          />
        </a>
      </Col>

      <Col xs="10" lg="10">
        <Row>
          <Col xs="12" lg="12">
            <Link
              style={{ borderBottom: "1px solid #eee ", fontSize: "0.89em" }}
              to={`${process.env.PUBLIC_URL}/${i18n.language}/offre-emploi/${props.avis.slug}/${props.avis.id}`}
              className="fontAlmari"
            >
              <div
                className="dangerouslySetInnerHTML"
                dangerouslySetInnerHTML={{
                  __html:
                    detailAvis.libelle == "null" ? null : detailAvis.libelle,
                }}
              ></div>
            </Link>
          </Col>
          <Col xs="12" lg="12" style={{ display: "flex" }}>
            <p className="societe col-6 col-sm-8">
              <FontAwesomeIcon
                style={{ marginRight: 2, color: "#255025" }}
                icon={faMapMarkerAlt}
                size="xs"
              />
              {detailAvis.ville}
            </p>

            <p className="col-6 col-sm-4">
              <span className="societe" style={{ padding: "2px" }}>
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ marginRight: 2, color: "#255025" }}
                  size="xs"
                />
                {props.avis.counter}
              </span>
              <span
                className="societe"
                style={{
                  padding: "2px",
                  color: "rgb(173, 8, 31)",
                  textAlign: "right",
                }}
              >
                <FontAwesomeIcon
                  icon={faClock}
                  size="xs"
                  spin
                  style={{ marginRight: 2 }}
                />
                {format(new Date(props.avis.fin), "dd/MM/yyyy")}
              </span>
            </p>
          </Col>
          <Col xs="10" lg="10">
            <p className="societe">
              <FontAwesomeIcon
                icon={faBuilding}
                size="xs"
                style={{ marginRight: 2 }}
              />
              {props.avis.societe.nom}
            </p>
          </Col>
          <Col xs="2" lg="2">
            <Button
              className="btn-fabars"
              style={{ width: "34px", border: "none" }}
              onClick={onClick}
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs="2" lg="2"></Col>
      <Col xs="10" lg="10">
        {showResults == 1 ? (
          <Row style={{ marginTop: "7px" }}>
            <Col
              xs="1"
              lg="1"
              style={{
                fontSize: "10px",
                color: "#686c68",
                alignContent: "center",
                margin: "0px",
              }}
            >
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                style={{ padding: "0px 2px 0px 2px" }}
              />
            </Col>
            <Col
              xs="11"
              lg="6"
              style={{
                fontSize: "10px",
                color: "#686c68",
                alignContent: "center",
                margin: "0px",
              }}
            >
              {props.avis.domaine.split("|").map((dom) =>
                props.domaines
                  .filter((d) => d.code == dom)
                  .map((domaine) => {
                    return (
                      <li key={domaine.id}>
                        {i18n.language == "fr" ? domaine.nom : domaine.nom_ar}
                        <br />
                      </li>
                    );
                  })
              )}
            </Col>
            <Col
              xs="6"
              lg="3"
              style={{
                fontSize: "10px",
                color: "#686c68",
                alignContent: "center",
                margin: "0px",
              }}
            >
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                style={{ padding: "0px 2px 0px 2px" }}
              />
              {t(props.avis.niveau)}
            </Col>
            <Col
              xs="6"
              lg="2"
              style={{
                fontSize: "10px",
                color: "#686c68",
                alignContent: "center",
                margin: "0px",
              }}
            >
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                style={{ padding: "0px 2px 0px 2px" }}
              />
              {t(props.avis.experience)} {t("ans")}
            </Col>
          </Row>
        ) : null}
      </Col>
    </Row>
  );
}
