import React from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import OffreEmploi from "./OffreEmploi";
import AppelOffres from "./AppelOffres";
import { useTranslation } from "react-i18next";

export default function AlaUne(props) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Row
        className="d-sm-inline-flex"
        style={{ margin: "auto", width: "100%" }}
      >
        <Col xs="12" lg="6">
          {props.offresEmploisAlaUne_fr.length != 0 && i18n.language == "fr" ? (
            <div style={{ margin: "5px 5px 0px 5px" }}>
              <center>
                <p
                  className="fontAlmari"
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    borderTop: "10px solid #255025",
                    borderBottom: "2px solid #255025",
                  }}
                >
                  {t("Offres_emploi_ala_une")}
                </p>
              </center>
              <Carousel>
                {props.offresEmploisAlaUne_fr.map((o) => (
                  <Carousel.Item key={o.id} interval={2200}>
                    <OffreEmploi avis={o} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          ) : (
            ""
          )}

          {props.offresEmploisAlaUne_ar.length != 0 && i18n.language == "ar" ? (
            <div style={{ margin: "5px 5px 0px 5px" }}>
              <center>
                <p
                  className="fontAlmari"
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    borderTop: "10px solid rgb(173, 8, 31) ",
                    borderBottom: "2px solid rgb(173, 8, 31) ",
                  }}
                >
                  {t("Offres_emploi_ala_une")}
                </p>
              </center>
              <Carousel>
                {props.offresEmploisAlaUne_ar.map((o) => (
                  <Carousel.Item key={o.id} interval={2200}>
                    <OffreEmploi avis={o} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          ) : (
            ""
          )}
        </Col>

        <Col xs="12" lg="6">
          {props.appelsOffresAlaUne_fr.length != 0 && i18n.language == "fr" ? (
            <div style={{ margin: "5px 5px 0px 5px" }}>
              <center>
                <p
                  className="fontAlmari"
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    borderTop: "10px solid rgb(173, 8, 31) ",
                    borderBottom: "2px solid rgb(173, 8, 31) ",
                  }}
                >
                  {t("Appel_offres_ala_Une")}
                </p>
              </center>
              <Carousel>
                {props.appelsOffresAlaUne_fr.map((o) => (
                  <Carousel.Item key={o.id} interval={2200}>
                    <AppelOffres avis={o} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          ) : (
            ""
          )}

          {props.appelsOffresAlaUne_ar.length != 0 && i18n.language == "ar" ? (
            <div style={{ margin: "5px 5px 0px 5px" }}>
              <center>
                <p
                  className="fontAlmari"
                  style={{
                    background: "rgb(98, 95, 95)",
                    color: "#fff",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {t("Appel_offres_ala_Une")}
                </p>
              </center>
              <Carousel>
                {props.appelsOffresAlaUne_ar.map((o) => (
                  <Carousel.Item key={o.id} interval={2200}>
                    <AppelOffres avis={o} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  );
}
