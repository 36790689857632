import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMobileAlt,
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const Contact = (props) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = "RIMTIC" + " - " + t("contact");
  }, []);

  return (
    <Container>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <p
          className="fontAlmari"
          style={{
            border: "solid 2px",
            borderLeft: "0px",
            borderRight: "0px",
            borderColor: "black",
            textAlign: "center",
            fontSize: "25px",
            color: "black",
            padding: "10px",
          }}
        >
          {t("contact")}
        </p>
      </div>

      <Row>
        <Col xs={12} sm={6}>
          <div className="panel panel-default">
            <div
              className="panel-body"
              style={{
                background: "#c0d7bb",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              {i18n.language == "fr" ? (
                <>
                  <p>
                    {" "}
                    <FontAwesomeIcon icon={faMobileAlt} />{" "}
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      style={{
                        padding: "0px 3px 0px 3px",
                        color: "green",
                        fontSize: "17px",
                      }}
                    />{" "}
                    +222 42 17 17 17
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon icon={faMobileAlt} /> +222 38 46 43 59
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon icon={faPhone} /> +222 45 29 24 13
                  </p>
                  <br />
                </>
              ) : (
                <>
                  <p>
                    {" "}
                    <FontAwesomeIcon icon={faMobileAlt} />{" "}
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      style={{
                        padding: "0px 3px 0px 3px",
                        color: "green",
                        fontSize: "17px",
                      }}
                    />
                    22242171717+
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon icon={faMobileAlt} /> 22238464359+{" "}
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon icon={faPhone} /> 22245292413+{" "}
                  </p>
                  <br />
                </>
              )}

              <p>
                {" "}
                <FontAwesomeIcon icon={faEnvelope} /> contact@rimtic.com{" "}
              </p>
              <p>
                {" "}
                <FontAwesomeIcon icon={faEnvelope} /> rimtic1@gmail.com{" "}
              </p>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={6}>
          <div
            className="panel-body"
            style={{
              background: "#c0d7bb",
              padding: "10px",
              borderRadius: "4px",
              height: "100%",
              textAlign: "center",
              margin: "auto",
            }}
          >
            <p>
              {" "}
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ color: "red", fontSize: "45px" }}
              />{" "}
            </p>
            <p className="fontAlmari">
              LOT 375 F-NORD - {t("tvz")} <br /> {t("nktt")}, {t("rim")}{" "}
            </p>
          </div>
        </Col>

        <Col xs={12} sm={12}>
          <div className="mapouter" style={{ marginTop: "10px" }}>
            <div className="gmap_canvas">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d450.5678694448351!2d-15.96895409204057!3d18.109629604671333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xe964dbaf28e2dc3%3A0xd01e43d3b9c8ff06!2sRIMTIC!5e1!3m2!1sen!2s!4v1724240261049!5m2!1sen!2s"
                width="100%"
                height="300"
                id="gmap_canvas"
              ></iframe>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
