import React, {useEffect, StrictMode } from 'react';
import Home from "./pages/Home";
import OffreEmploiDetail from "./pages/offreEmploiDetail";
import AppelOffresDetail from "./pages/appelOffresDetail";
import AvisDetail from "./pages/avisDetail";
import OffreEmploiPublier from "./pages/offreEmploiPublier";
import AppelOffresPublier from "./pages/appelOffresPublier";
import AvisPublier from "./pages/avisPublier"; 
import ModelesCV from "./pages/modelesCV";
import Admin from "./pages/pageAdmin/admin";
import ModelesLettres from "./pages/modelesLettres";
import Contact from "./pages/contact";
import Login from "./pages/login";
import Entete from "./components/Entete";
import Footer from "./components/footer";
import './App.css';
import AdminAfficherDetailOffreEmploi from './pages/pageAdmin/adminAfficherDetailOffreEmploi'
import AdminAfficherDetailAppeleOffer from './pages/pageAdmin/adminAfficherDetailAppeleOffer'
import AdminAfficherDetailAvis from './pages/pageAdmin/adminAfficherDetailAvis'
import AfficherOffresEmploi from './pages/pageAdmin/afficherOffresEmploi'
import AfficherAppelsOffres from './pages/pageAdmin/afficherAppelsOffres'
import AfficherSocietes from './pages/pageAdmin/afficherSocietes'
import EditerOffreEmploi from './pages/pageAdmin/editerOffreEmploi'
import EditerAppelOffres from './pages/pageAdmin/editerAppelOffres'
import AfficherDetailSocietes from './pages/pageAdmin/afficherDetailSocietes'
import EditeSociete from './pages/pageAdmin/editeSocite'
import EditerAvisPublication from './pages/pageAdmin/editerAvisPublication' 
import ReussirVotreEntretien from "./pages/reussirVotreEntretien";
import AfficherTousAvis from "./pages/pageAdmin/afficherTousAvis";
import AccueilTraducteur from "./pages/traduction/accueilTraducteur";
import TraductionOffreEmploi from "./pages/traduction/traductionOffreEmploi";
import TraductionAppelOffre from "./pages/traduction/traductionAppelOffre";
import TraductionAvis from "./pages/traduction/traductionAvis";

import { Routes, Route, Navigate } from 'react-router-dom';
import Article from './pages/article';
import { useTranslation } from "react-i18next";


function App() {
const { t, i18n } = useTranslation();

   
  return ( 
      
      <>
        {window.location.href.includes('/tic') ? null : 
        <Entete/> 
        }
        <Routes> 
          <Route exact path="/:langue/" element={<Home />} />
          <Route exact path="/offre-emploi/:slug/:id" element={<OffreEmploiDetail />} />
          <Route exact path="/:langue/offre-emploi/:slug/:id" element={<OffreEmploiDetail />} />
          <Route exact path="/appel-offres/:slug/:id" element={<AppelOffresDetail />} />
          <Route exact path="/:langue/appel-offres/:slug/:id" element={<AppelOffresDetail />} />
          <Route exact path="/avis/:slug/:id" element={<AvisDetail />} />
          <Route exact path="/:langue/avis/:slug/:id" element={<AvisDetail />} />
          <Route exact path="/:langue/deposer_offre_emploi" element={<OffreEmploiPublier />} />
          <Route exact path="/:langue/deposer_appel_offres" element={<AppelOffresPublier />} />
          <Route exact path="/:langue/deposer_avis" element={<AvisPublier />} /> 
          <Route exact path="/modelesCV" element={<ModelesCV />} />
          <Route exact path="/modelesLettres" element={<ModelesLettres />} />
          <Route exact path="/reussirVotreEntretien" element={<ReussirVotreEntretien />} />
          <Route exact path="/article/:id" element={<Article />} />

          <Route exact path="/contact" element={<Contact />} />
          <Route path="*" element={<Home/>} />
          <Route path="/login" element={<Login />} />

          
          {/* routes admin */}
          <Route path="/tic" element={<Admin />} />
          <Route exact path="/tic/demploie/:id" element={<AdminAfficherDetailOffreEmploi />} />
          <Route exact path="/tic/doffre/:id" element={<AdminAfficherDetailAppeleOffer />} />
          <Route exact path="/tic/davis/:id" element={<AdminAfficherDetailAvis />} />
          <Route exact path="/tic/afficherOffresEmploi" element={<AfficherOffresEmploi />} />
          <Route exact path="/tic/afficherAppelsOffres" element={<AfficherAppelsOffres />} />
          <Route exact path="/tic/afficherTousAvis" element={<AfficherTousAvis />} />
          <Route exact path="/tic/afficherSocietes" element={<AfficherSocietes />} />
          <Route exact path="/tic/demploie/modifier/:id" element={<EditerOffreEmploi />} />
          <Route exact path="/tic/doffre/modifier/:id" element={<EditerAppelOffres />} />
          <Route exact path="/tic/desociete/:id" element={<AfficherDetailSocietes />} />
          <Route exact path="/tic/dsociete/modifier/:id" element={<EditeSociete />} />
          <Route exact path="tic/davis/modifier/:id" element={<EditerAvisPublication />} />

          <Route exact path="/trad" element={<AccueilTraducteur />} />
          <Route exact path="/traduction/offreEmploi/:id" element={<TraductionOffreEmploi />} />
          <Route exact path="/traduction/appelOffre/:id" element={<TraductionAppelOffre />} />
          <Route exact path="/traduction/avis/:id" element={<TraductionAvis />} />


        </Routes>
        <Footer/>
      </> 
  );
}

export default App;
