import React, { useEffect, useState } from "react";
import { Row, Col, Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "../lib/axios";
import { BrowserView, MobileView } from "react-device-detect";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faLanguage } from "@fortawesome/free-solid-svg-icons";
const Entete = () => {
  const { t, i18n } = useTranslation();
  import("../css/style_" + i18n.language + ".css");
  const [add_p1, setAdd_p1] = useState([]);
  const [add_p2, setAdd_p2] = useState([]);
  const [add_p3, setAdd_p3] = useState([]);
  const [add_p4, setAdd_p4] = useState([]);
  const [add_p5, setAdd_p5] = useState([]);

  useEffect(() => {
    getAdds();
  }, []);

  const getAdds = () => {
    axios
      .get("/api/adds", {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        setAdd_p1([]);
        setAdd_p2([]);
        setAdd_p3([]);
        setAdd_p4([]);
        setAdd_p5([]);
        if (response.data.adds != null)
          response.data.adds.map((e) => {
            if (e.position == 1) setAdd_p1((cu) => [...cu, e]);
            if (e.position == 2) setAdd_p2((cu) => [...cu, e]);
            if (e.position == 3) setAdd_p3((cu) => [...cu, e]);
            if (e.position == 4) setAdd_p4((cu) => [...cu, e]);
            if (e.position == 5) setAdd_p5((cu) => [...cu, e]);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <header>
        <Row style={{ margin: "auto" }}>
          <Col xs={4} sm={4} className="img-in-nav">
            {add_p1.map((a, key) => (
              <p key={key}>
                <a href={a.target} target="_blank">
                  <img
                    src={"https://www.rimtic.com/storage/images/" + a.image}
                  />
                </a>
              </p>
            ))}
          </Col>
          <Col xs={4} sm={4} className="img-in-nav">
            <Link to={`/${i18n.language}/`}>
              <MobileView>
                <img src="https://rimtic.com/images/logoRIMTIC.png" />
              </MobileView>
              <BrowserView>
                <img
                  src="https://rimtic.com/images/logoRIMTIC.png"
                  style={{ width: "70%" }}
                />
              </BrowserView>
            </Link>
          </Col>
          <Col xs={4} sm={4}>
            {add_p2.map((a, key) => (
              <p key={key}>
                <a href={a.target} target="_blank">
                  <img
                    src={"https://www.rimtic.com/storage/images/" + a.image}
                  />
                </a>
              </p>
            ))}
          </Col>
        </Row>

        <Navbar
          expand="lg"
          style={{ justifyContent: "center", display: "grid", padding: 0 }}
        >
          <div style={{ display: "flex" }}>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
            />

            <Dropdown className="d-block  d-sm-none">
              <Dropdown.Toggle
                className="btn-langue"
                variant="success"
                id="dropdown-basic"
              >
                <FontAwesomeIcon icon={faGlobe} style={{ fontSize: "20px" }} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {i18n.language == "fr" ? (
                  <Dropdown.Item
                    onClick={() => {
                      if (
                        !window.location.href.includes("/ar/") &&
                        !window.location.href.includes("/fr/")
                      ) {
                        i18n.changeLanguage("ar");
                        window.location.href = window.location.href + "ar/";
                      } else {
                        window.location.href = window.location.href.replace(
                          "/fr/",
                          "/ar/"
                        );
                      }
                    }}
                    className="fontAlmari"
                    style={{
                      color: "black",
                      fontWeight: "700",
                      textTransform: "uppercase",
                      fontFamily: "Almarai",
                    }}
                  >
                    العربية
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    onClick={() => {
                      if (
                        !window.location.href.includes("/ar/") &&
                        !window.location.href.includes("/fr/")
                      ) {
                        i18n.changeLanguage("fr");
                        window.location.href = window.location.href + "fr/";
                      } else {
                        window.location.href = window.location.href.replace(
                          "/ar/",
                          "/fr/"
                        );
                      }
                    }}
                    className="fontAlmari"
                    style={{
                      color: "black",
                      fontWeight: "700",
                      textTransform: "uppercase",
                    }}
                  >
                    Francais
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                eventKey="1"
                className="fontAlmari"
                as={Link}
                to={`/${i18n.language}/`}
              >
                {t("accueil")}
              </Nav.Link>

              <NavDropdown
                className="fontAlmari"
                title={t("entreprises")}
                id="e-nav"
              >
                <NavDropdown.Item
                  eventKey="2"
                  as={Link}
                  to={`/${i18n.language}/deposer_offre_emploi`}
                >
                  {t("depose_offre_emploi")}
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="3"
                  as={Link}
                  to={`/${i18n.language}/deposer_appel_offres`}
                >
                  {" "}
                  {t("depose_appel_offre")}{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="4"
                  as={Link}
                  to={`/${i18n.language}/deposer_avis`}
                >
                  {t("depose_avis")}
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="6" as={Link} to="/article/1">
                  {t("menu_hr_solutions")}
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                className="fontAlmari"
                title={t("candidats")}
                id="c-nav"
              >
                <NavDropdown.Item
                  eventKey="6"
                  href="https://rimtic.com/cvtheque-f/inscrire"
                >
                  {t("deposer_votre_CV")}{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="7"
                  href="https://rimtic.com/cvtheque-f/profile"
                >
                  {t("gerer_votre_CV")}{" "}
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                className="fontAlmari"
                title={t("modele")}
                id="c-nav"
              >
                <NavDropdown.Item eventKey="8" as={Link} to="/modelesCV">
                  {t("CVs")}
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="9" as={Link} to="/modelesLettres">
                  {t("lettres_de_motivation")}
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                eventKey="10"
                className="fontAlmari"
                as={Link}
                to="/contact"
              >
                {t("contact")}
              </Nav.Link>

              <Nav.Link style={{ height: "90%" }} as={Link} to="/contact">
                <img src="https://rimtic.com/dgMarket-Mauritanie.gif" />
              </Nav.Link>

              {i18n.language == "fr" ? (
                <Nav.Link
                  eventKey="11"
                  onClick={() => {
                    if (
                      !window.location.href.includes("/ar/") &&
                      !window.location.href.includes("/fr/")
                    ) {
                      i18n.changeLanguage("ar");
                      window.location.href = window.location.href + "ar/";
                    } else {
                      window.location.href = window.location.href.replace(
                        "/fr/",
                        "/ar/"
                      );
                    }
                  }}
                  className="fontAlmari d-none d-lg-block"
                  style={{
                    color: "black",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    fontFamily: "Almarai",
                  }}
                >
                  {" "}
                  العربية
                </Nav.Link>
              ) : (
                <Nav.Link
                  eventKey="11"
                  onClick={() => {
                    if (
                      !window.location.href.includes("/ar/") &&
                      !window.location.href.includes("/fr/")
                    ) {
                      i18n.changeLanguage("fr");
                      window.location.href = window.location.href + "fr/";
                    } else {
                      window.location.href = window.location.href.replace(
                        "/ar/",
                        "/fr/"
                      );
                    }
                  }}
                  className="fontAlmari d-none d-lg-block "
                  style={{
                    color: "black",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  Francais
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
      <Row className="middle-menu center-text" style={{ margin: "auto" }}>
        <Col xs={12} sm={12} className="img-in-nav">
          {add_p3.map((a, key) => (
            <p key={key}>
              <a href={a.target} target="_blank">
                <img
                  src={"https://www.rimtic.com/storage/images/" + a.image}
                  style={{ height: "60px", width: "100%" }}
                />
              </a>
            </p>
          ))}
        </Col>

        <Col xs={12} sm={12} className="mt-2 pb-2">
          <div className="scroll-container">
            <div className="scroll-content">
              {add_p4.map((a, key) => (
                <p key={key}>
                  <a href={a.target} target="_blank">
                    <img
                      src={"https://www.rimtic.com/storage/images/" + a.image}
                      style={{ height: "40px", width: "auto" }}
                    />
                    <span className="ms-2 fontAlmari"> {a.text} </span>
                  </a>
                </p>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Entete;
